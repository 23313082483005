define("libkey-web/mixins/localized-adapter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ajaxOptions() {
    var _this = this;
    var options = this._super.apply(this, arguments);
    var originalBeforeSend = options.beforeSend;
    options.beforeSend = function (xhr) {
      var intl = _this.intl;
      var primaryLocale = intl.get('primaryLocale') || 'en-us';
      xhr.setRequestHeader('Accept-Language', primaryLocale);
      if (originalBeforeSend) {
        originalBeforeSend(xhr);
      }
    };
    return options;
  }

  /**
   * Use this mixin to setup ajaxOptions to send
   * the current locale on the Accept-Language header
   */

  // eslint-disable-next-line ember/no-new-mixins
  var _default = _exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    ajaxOptions: ajaxOptions,
    moreAjaxOptions: ajaxOptions // to be referenced by other mixins overloading ajaxOptions
  });
});
