define("libkey-web/components/route-announcer", ["exports", "a11y-announcer/components/route-announcer"], function (_exports, _routeAnnouncer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _routeAnnouncer.default;
    }
  });
});
