define("libkey-web/initializers/add-announcer-to-router", ["exports", "libkey-web/router"], function (_exports, _router) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'add-announcer-to-router',
    initialize: function initialize() {
      _router.default.reopen({
        announcer: Ember.inject.service('announcer'),
        init: function init() {
          var _this = this;
          this._super.apply(this, arguments);
          this.on('routeDidChange', function () {
            _this._timerId = Ember.run.later(function () {
              if (_this.isDestroying || _this.isDestroyed) {
                return;
              }
              var pageTitle = document.title.trim();
              var serviceMessage = _this.get('announcer.message');
              var message = "".concat(pageTitle, " ").concat(serviceMessage);
              _this.get('announcer').announce(message, 'polite');
            }, 100);
          });
        },
        willDestroy: function willDestroy() {
          Ember.run.cancel(this._timerId);
          this._super();
        }
      });
    }
  };
});
