define("libkey-web/utils/timeout-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = timeoutPromise;
  function timeoutPromise(ms, promise, timeoutMessage) {
    return new Promise(function (resolve, reject) {
      var timeoutId = setTimeout(function () {
        var error = new Error(timeoutMessage);
        error.code = 'timeout';
        reject(error);
      }, ms);
      promise.then(function (res) {
        clearTimeout(timeoutId);
        resolve(res);
      }, function (err) {
        clearTimeout(timeoutId);
        reject(err);
      });
    });
  }
});
