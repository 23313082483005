define("libkey-web/mixins/openurl-route", ["exports", "libkey-web/utils/doi-utils"], function (_exports, _doiUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function checkQueryParamsForId(transition, valueCheckingFunction) {
    var queryParams = transition.to.queryParams,
      intent = transition.intent;
    if (intent.url) {
      var _intent$url$split = intent.url.split('?'),
        _intent$url$split2 = _slicedToArray(_intent$url$split, 2),
        queryString = _intent$url$split2[1];
      var searchParams = new URLSearchParams(queryString);
      var _iterator = _createForOfIteratorHelper(searchParams.values()),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var paramValue = _step.value;
          var foundId = valueCheckingFunction(paramValue);
          if (foundId) {
            return foundId;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    } else {
      var emberQueryParams = queryParams;
      for (var _i = 0, _Object$keys = Object.keys(emberQueryParams); _i < _Object$keys.length; _i++) {
        var paramName = _Object$keys[_i];
        var _paramValue = emberQueryParams[paramName];
        var _foundId = valueCheckingFunction(_paramValue);
        if (_foundId) {
          return _foundId;
        }
      }
    }
  }

  // eslint-disable-next-line ember/no-new-mixins
  var _default = _exports.default = Ember.Mixin.create({
    checkQueryParamsForPmid: function checkQueryParamsForPmid(transition) {
      var queryParams = transition.to.queryParams,
        intent = transition.intent;
      function checkValueForPmid(paramValue) {
        var pmidRegex = /^((info:pmid\/)|(pmid:))(\d+)$/;
        var matchAttempt = pmidRegex.exec(paramValue);
        if (matchAttempt) {
          return matchAttempt[4];
        }
      }

      // First look for an explicit pmid param
      var pmidValue;
      if (intent.url) {
        var _intent$url$split3 = intent.url.split('?'),
          _intent$url$split4 = _slicedToArray(_intent$url$split3, 2),
          queryString = _intent$url$split4[1];
        var searchParams = new URLSearchParams(queryString);
        pmidValue = searchParams.get('pmid');
      } else {
        pmidValue = queryParams.pmid;
      }
      if (pmidValue) {
        return pmidValue;
      }

      // Otherwise search all the query params for something that says its a pmid
      return checkQueryParamsForId(transition, checkValueForPmid);
    },
    checkQueryParamsForDOI: function checkQueryParamsForDOI(transition) {
      function checkValueForDOI(paramValue) {
        var matchAttempt = _doiUtils.doiRegex.exec(paramValue);
        if (matchAttempt) {
          return matchAttempt[0];
        }
      }
      return checkQueryParamsForId(transition, checkValueForDOI);
    }
  });
});
