define("libkey-web/initializers/aria-attributes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    [Ember.TextArea, Ember.TextField].forEach(function (helper) {
      helper.reopen({
        attributeBindings: ['aria-label']
      });
    });
    Ember.LinkComponent.reopen({
      attributeBindings: ['aria-label']
    });
  }
  var _default = _exports.default = {
    name: 'aria-attributes',
    initialize: initialize
  };
});
