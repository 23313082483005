define("libkey-web/mixins/article-loading-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This mixin captures helper functions
  // common to routes that
  // display the article-loading-box
  // component
  // eslint-disable-next-line ember/no-new-mixins
  var _default = _exports.default = Ember.Mixin.create({
    // Takes promises that resolve to the
    // article model (with journal model and issue model icluded)
    // that we're loading and to the library's model
    //
    // Returns a promise that
    // resovles to an object
    // with all the properties the
    // article-loading-box component
    // needs
    prepareArticleLoadingBoxModel: function prepareArticleLoadingBoxModel(findArticleWithJournalAndIssue, findLibrary) {
      return findArticleWithJournalAndIssue.then(function (article) {
        var getIssue, getJournal;
        if (!article) {
          getIssue = Ember.RSVP.resolve(undefined);
          getJournal = Ember.RSVP.resolve(undefined);
        } else {
          if (article.get('journal')) {
            getJournal = Ember.RSVP.resolve(article.get('journal'));
          } else {
            getJournal = article.fixedBelongsToLoad('journal');
          }
          if (article.get('inPress')) {
            getIssue = Ember.Object.create({
              library: findLibrary,
              //TODO: Validate why article.get('library') is missing data properties.
              //Seems like article.get('library') is maybe returning the the unauthenticated library?
              //JIRA https://thirdiron.atlassian.net/browse/BZ-3585
              journal: getJournal,
              title: 'Articles in Press',
              date: new Date().toISOString().split('T')[0],
              year: new Date().getFullYear()
            });
          } else {
            if (article.get('issue')) {
              getIssue = Ember.RSVP.resolve(article.get('issue'));
            } else {
              getIssue = article.fixedBelongsToLoad('issue');
            }
          }
        }
        return Ember.RSVP.allSettled([findLibrary, getJournal, getIssue]).then(function (values) {
          if (!article) {
            return Ember.RSVP.hash({
              article: undefined,
              issue: undefined,
              library: values[0].value,
              journal: undefined
            });
          }
          var library = values[0].value,
            journal = values[1].value || article.get('journal'),
            issue = values[2].value;

          // We have to relate the journal and issue models that came back to the selected library
          // or else our adapters won't be able to construct API URLs properly for them
          if (journal && journal.belongsTo && library) {
            var journalLibraryRef = journal.belongsTo('library');
            journalLibraryRef.push(library);
          }

          // If we have an actual issue model rather than a articles-in-press "issue"
          // set it's library relationship too
          if (issue && issue.belongsTo && library) {
            var issueLibraryRef = issue.belongsTo('library');
            issueLibraryRef.push(library);
          }
          return Ember.RSVP.hash({
            article: article,
            issue: issue,
            library: library,
            journal: journal
          });
        });
      });
    },
    hasFullTextAccess: function hasFullTextAccess(model, articleHasContentLocationLink) {
      return this.hasAccessIfHasLink(model) && articleHasContentLocationLink;
    },
    hasFullTextFileAccess: function hasFullTextFileAccess(model, articleHasFullTextFileLink) {
      return this.hasAccessIfHasLink(model) && articleHasFullTextFileLink;
    },
    /**
    * Helper function to capture conditions common to determining if the user has full text access
    * and determining if the user has full text file access
    */
    hasAccessIfHasLink: function hasAccessIfHasLink(model) {
      return !(model.journal.get('accessedThroughAggregator') && model.article.get('inPress')) && !(!!model.journal.get('embargoDescription') && model.article.get('inPress')) && !model.issue.get('embargoed') && !model.article.get('vpnRequired');
    },
    // Takes the name of a property that may
    // hold a URL to redirect to.  If set,
    // sets things up so that a redirect happens
    // shortly after this route renders
    setupRedirectAfterRender: function setupRedirectAfterRender(redirectOptions) {
      var _this = this;
      var timeUntilTransition = 3000;

      // handle options that provide a property that holds a URL
      // to handle redirects that go out of BZWeb
      if (redirectOptions.urlPropertyName) {
        var redirectUrl = this.get(redirectOptions.urlPropertyName);
        if (redirectUrl) {
          //eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
          Ember.run.scheduleOnce('afterRender', this, function () {
            window.location.assign(redirectUrl);
          });
        }
      }

      // For redirects that remain in BZWeb, look for transitionToArguments
      if (redirectOptions.transitionToArgumentsPropertyName) {
        var transitionToArguments = this.get(redirectOptions.transitionToArgumentsPropertyName);
        if (transitionToArguments) {
          //eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
          Ember.run.scheduleOnce('afterRender', this, function () {
            // Transitions go so fast we need to delay a little bit so
            // the interstitial actually displays for a little bit!
            Ember.run.later(_this, function () {
              _this.transitionTo.apply(_this, transitionToArguments);
            }, timeUntilTransition);
          });
        }
      }
    }
  });
});
