define("libkey-web/utils/local-storage-json-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLocalStorageJsonProperty = getLocalStorageJsonProperty;
  _exports.setLocalStorageJsonProperty = setLocalStorageJsonProperty;
  function getLocalStorageJsonProperty(key) {
    if (!localStorage.getItem(key)) {
      return undefined;
    }
    return JSON.parse(localStorage.getItem(key));
  }
  function setLocalStorageJsonProperty(key, value) {
    if (!value) {
      localStorage.removeItem(key);
      return value;
    }
    localStorage.setItem(key, JSON.stringify(value));
    return value;
  }
});
