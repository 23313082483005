define("libkey-web/mixins/authenticating-adapter", ["exports", "libkey-web/utils/interpret-error"], function (_exports, _interpretError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Use this mixin to setup ajaxOptions to send
   * over API credentials for the selected library
   * with the request.
   */
  // eslint-disable-next-line ember/no-new-mixins
  var _default = _exports.default = Ember.Mixin.create({
    auth: Ember.inject.service(),
    applicationSession: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ajaxOptions: function ajaxOptions() {
      var _this = this;
      var options = this._super.apply(this, arguments);
      var originalBeforeSend = options.beforeSend;
      options.beforeSend = function (xhr) {
        var selectedLibraryId = _this.applicationSession.get('selectedLibrary');
        if (selectedLibraryId && selectedLibraryId !== 'unaffiliated') {
          var authHeader = _this.auth.getAPIAuthHeaderForLibrary(selectedLibraryId);
          if (authHeader) {
            xhr.setRequestHeader('Authorization', authHeader);
          }
        }
        if (originalBeforeSend) {
          originalBeforeSend(xhr);
        }
      };
      return options;
    },
    /**
     * Helper to be called from handleResponse method of adapters
     * to provide standardized interpretation of 401 & 403 responses
     *
     * returns custom error on responses it interprets, otherwise nothing
     */
    handleUnauthorizedResponse: function handleUnauthorizedResponse(status, headers, payload) {
      var _this2 = this;
      if (status === 401) {
        return (0, _interpretError.default)(status, payload);
      } else if (status === 403) {
        var auth = this.auth;
        var applicationSession = this.applicationSession;
        var loggedInUser = applicationSession.get('loggedInUser');
        var token = (loggedInUser || {}).token;
        Ember.run(function () {
          auth.authenticateUser(null, null, token);
          _this2.flashMessages.add({
            message: '',
            type: 'alert',
            componentName: 'permissions-error-alert',
            sticky: true
          });
        });
        return new Error('invalid permissions');
      }
    }
  });
});
