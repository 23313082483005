define("libkey-web/initializers/component-router-injector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.inject('component', 'router', 'router:main');
  }
  var _default = _exports.default = {
    name: 'component-router-injector',
    initialize: initialize
  };
});
