define("libkey-web/instance-initializers/announce-flash-messages", ["exports", "ember-cli-flash/flash/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    var announcer = application.lookup('service:announcer');
    _object.default.reopen({
      announce: Ember.on('init', function () {
        var message = "".concat(this.type, ": ").concat(this.message);
        announcer.announce(message, 'assertive');
      })
    });
  }
  var _default = _exports.default = {
    name: 'announce-flash-messages',
    initialize: initialize
  };
});
