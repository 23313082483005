define("libkey-web/utils/get-matching-holdings-tool-subdomain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMatchingHoldingsToolSubdomain;
  function getMatchingHoldingsToolSubdomain(windowLocationHostname, holdingsToolSubdomains) {
    if (holdingsToolSubdomains) {
      var appHostnameWithoutLibkeyIo = windowLocationHostname.replace('libkey.io', '');
      if (appHostnameWithoutLibkeyIo) {
        var possibleHoldingsToolSubdomain = appHostnameWithoutLibkeyIo.split('.')[0];
        return holdingsToolSubdomains.find(function (subdomain) {
          // (the endsWith option is for feature branch domains, e.g., 'feature-branch-incdocs-develop.libkey.io')
          return possibleHoldingsToolSubdomain === subdomain || possibleHoldingsToolSubdomain.endsWith("-".concat(subdomain));
        });
      }
    }
  }
});
