define("libkey-web/utils/should-send-retracted-or-eoc-analytics-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shouldSendRetractedOrEocAnalyticsEvent;
  function shouldSendRetractedOrEocAnalyticsEvent() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      product = _ref.product,
      utmSource = _ref.utmSource;
    if (product && ['libkeyapi', 'libkeynomad'].includes(product.toLowerCase())) {
      return false;
    } else {
      if (!utmSource) {
        return true;
      }
      return !['bzweb', 'bzios', 'bzandroid'].includes(utmSource.toLowerCase());
    }
  }
});
