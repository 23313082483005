define("libkey-web/instance-initializers/route-styles", ["exports", "ember-component-css/instance-initializers/route-styles"], function (_exports, _routeStyles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _routeStyles.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _routeStyles.initialize;
    }
  });
});
