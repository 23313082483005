define("libkey-web/utils/interpret-error", ["exports", "libkey-web/errors/ApiSessionExpiredError", "libkey-web/errors/AuthRequiredError"], function (_exports, _ApiSessionExpiredError, _AuthRequiredError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = interpretError;
  /**
   * Interprets a JSON API error payload.  When the error corresponds to a
   * custom error type defined in the app, returns the appropriate custom error type.
   * Otherwise returns nothing
   */

  var LIBRARY_SESSION_EXPIRED_CODE = 'expired_library_session';
  function interpretError(httpStatus, errorPayload) {
    if (httpStatus === 401 && errorPayload.code === LIBRARY_SESSION_EXPIRED_CODE) {
      // The docs suggest DS.InvalidError may not be appropriate for session expirations
      var error = new _ApiSessionExpiredError.default(errorPayload);
      return error;
    } else if (httpStatus === 401) {
      var _error = new _AuthRequiredError.default(errorPayload);
      return _error;
    }
  }
});
