define("libkey-web/services/announcer", ["exports", "a11y-announcer/services/announcer"], function (_exports, _announcer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _announcer.default;
    }
  });
});
