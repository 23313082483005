define("libkey-web/initializers/flash-messages", ["exports", "libkey-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var deprecate = Ember.deprecate;
  var merge = Ember.assign || Ember.merge;
  var INJECTION_FACTORIES_DEPRECATION_MESSAGE = '[ember-cli-flash] Future versions of ember-cli-flash will no longer inject the service automatically. Instead, you should explicitly inject it into your Route, Controller or Component with `Ember.inject.service`.';
  var addonDefaults = {
    timeout: 3000,
    extendedTimeout: 0,
    priority: 100,
    sticky: false,
    showProgress: false,
    type: 'info',
    types: ['success', 'info', 'warning', 'danger', 'alert', 'secondary'],
    injectionFactories: ['route', 'controller', 'view', 'component'],
    preventDuplicates: false
  };
  function initialize() {
    var application = arguments[1] || arguments[0];
    var _ref = _environment.default || {},
      flashMessageDefaults = _ref.flashMessageDefaults;
    var _ref2 = flashMessageDefaults || [],
      injectionFactories = _ref2.injectionFactories;
    var options = merge(addonDefaults, flashMessageDefaults);
    var shouldShowDeprecation = !(injectionFactories && injectionFactories.length);
    application.register('config:flash-messages', options, {
      instantiate: false
    });
    application.inject('service:flash-messages', 'flashMessageDefaults', 'config:flash-messages');
    deprecate(INJECTION_FACTORIES_DEPRECATION_MESSAGE, shouldShowDeprecation, {
      id: 'ember-cli-flash.deprecate-injection-factories',
      until: '2.0.0'
    });
    options.injectionFactories.forEach(function (factory) {
      application.inject(factory, 'flashMessages', 'service:flash-messages');
    });
  }
  var _default = _exports.default = {
    name: 'flash-messages',
    initialize: initialize
  };
});
