define("libkey-web/utils/get-redirect-url-if-proxying", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getRedirectURLIfProxying;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function extractAuthority(url) {
    //Authority is defined in the URI RFC as userinfo@host:port
    //https://tools.ietf.org/html/rfc3986#section-3.2
    var authority;
    //find & remove protocol (http, ftp, etc.)
    if (url.indexOf("://") > -1) {
      authority = url.split('/')[2];
    } else {
      authority = url.split('/')[0];
    }
    return authority;
  }
  function getRedirectURLIfProxying(options) {
    var baseURLForProxyComparison = options.baseURLForProxyComparison,
      holdingsToolSubdomains = options.holdingsToolSubdomains,
      windowLocationURL = options.windowLocationURL;
    var expectedAuthority = extractAuthority(baseURLForProxyComparison);
    if (expectedAuthority && expectedAuthority.length > 0) {
      var holdingsToolExpectedAuthorities = holdingsToolSubdomains.split(',').map(function (s) {
        return "".concat(s, ".libkey.io");
      });
      var appIsServedViaProxy = [expectedAuthority].concat(_toConsumableArray(holdingsToolExpectedAuthorities)).map(function (ea) {
        return ea.indexOf(windowLocationURL.host);
      }).every(function (io) {
        return io === -1;
      });
      if (appIsServedViaProxy) {
        return "".concat(windowLocationURL.protocol, "//").concat(expectedAuthority).concat(windowLocationURL.pathname).concat(windowLocationURL.search);
      }
    }
  }
});
