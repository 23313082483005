define("libkey-web/helpers/library-wayf-entries-from-libraries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function libraryWayfEntriesFromLibraries(params /*, hash*/) {
    var libraries = params[0];
    if (!libraries || !libraries.length) {
      return libraries;
    } else if (libraries.toArray) {
      libraries = libraries.toArray();
    }
    var listEntries = libraries.flatMap(function (l) {
      if (!l.get('aliases') || !l.get('aliases').length) {
        // If no aliases return just an entry for the library
        return {
          entryName: l.get('name'),
          model: l
        };
      } else {
        // if aliases return an entry for each alias and one for the library
        var aliasEntries = l.get('aliases').map(function (a) {
          return {
            entryName: a,
            model: l
          };
        });
        aliasEntries.push({
          entryName: l.get('name'),
          model: l
        });
        return aliasEntries;
      }
    });
    var sortedFilteredEntries = listEntries.filter(function (entry) {
      return !!entry.entryName;
    }).sort(function (a, b) {
      return a.entryName.localeCompare(b.entryName);
    });
    return sortedFilteredEntries;
  });
});
