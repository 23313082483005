define("libkey-web/utils/merge-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mergeAll;
  /**
   * Helper function that Ember.merges an array of source objects
   * into the target
   */
  function mergeAll(target, sources) {
    if (!sources || !sources.length) {
      return target;
    }
    Object.assign(target, sources[0]);
    return mergeAll(target, sources.slice(1));
  }
});
