define("libkey-web/utils/doi-utils", ["exports"], function (_exports) {
  'use strict';

  // Matches string that contains a DOI
  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.doiRegex = void 0;
  _exports.findDoiInInput = findDoiInInput;
  _exports.strictDoiRegex = void 0;
  var doiRegex = _exports.doiRegex = /10.\d{4,9}\/[-<>._;()/:A-Z0-9]+/i;

  // Matches string that is a DOI
  var strictDoiRegex = _exports.strictDoiRegex = /^10.\d{4,9}\/[-<>._;()/:A-Z0-9]+/i;
  function findDoiInInput(input) {
    var noWhitespaceInput = input.replace(/\s/g, '');
    var candidateDoi;
    if (noWhitespaceInput.startsWith('http://doi.org/')) {
      candidateDoi = noWhitespaceInput.slice(15);
    } else if (noWhitespaceInput.startsWith('https://doi.org/')) {
      candidateDoi = noWhitespaceInput.slice(16);
    } else if (noWhitespaceInput.startsWith('http://dx.doi.org/')) {
      candidateDoi = noWhitespaceInput.slice(18);
    } else if (noWhitespaceInput.startsWith('https://dx.doi.org/')) {
      candidateDoi = noWhitespaceInput.slice(19);
    } else {
      candidateDoi = noWhitespaceInput;
    }
    if (candidateDoi.match(strictDoiRegex)) {
      return {
        foundDoi: true,
        doi: candidateDoi
      };
    } else {
      var decodedCandidateDoi = decodeURIComponent(candidateDoi);
      if (decodedCandidateDoi.match(strictDoiRegex)) {
        return {
          foundDoi: true,
          doi: decodedCandidateDoi
        };
      } else {
        return {
          foundDoi: false
        };
      }
    }
  }
});
