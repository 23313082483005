define("libkey-web/mixins/trigger-on-enter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-new-mixins
  var _default = _exports.default = Ember.Mixin.create({
    actions: {
      triggerOnEnter: function triggerOnEnter() {
        var e = arguments[arguments.length - 1];
        if (e.keyCode === 13) {
          var newArguments = [];
          for (var i = 1; i < arguments.length - 1; i++) {
            newArguments.push(arguments[i]);
          }
          this.send.apply(this, [arguments[0]].concat(newArguments));
        }
      }
    }
  });
});
