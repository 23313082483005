define("libkey-web/instance-initializers/configure-offline", ["exports", "libkey-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    var offline = application.lookup('service:offline');

    //Note: this sends HEAD requests
    offline.offline.options.checks = {
      xhr: {
        url: _environment.default.apiHost
      }
    };
  }
  var _default = _exports.default = {
    name: 'configure-offline',
    initialize: initialize
  };
});
